<template>
  <div class="mx-auto container container--fluid">
    <v-snackbar v-model="snackbar" :top="true" :color="color" :timeout="6000">
      <span v-if="snackbar_msg != null">{{ snackbar_msg }}</span>
      <v-btn dark text @click="snackbar = false">Close</v-btn>
    </v-snackbar>

    <v-toolbar dense class="tc-title">
      <v-toolbar-title>Scheduled Companies</v-toolbar-title>
      <v-spacer></v-spacer>
    </v-toolbar>
    {{company_list}}
    <v-data-table :headers="headers" :items="company_list" class="elevation-1"  v-if="company_list.length!=0">
    
      </v-data-table>



</div>
</template>


<script>
import axios from "axios";
//import acceptedimage from './tp.jpg'
export default {
  data: () => ({
    snackbar_msg: "",
    color: "",
    snackbar: false,
    company_list:[],
   
    headers: [
      { text: "Company.", value: "company_name", sortable: true },
      { text: "Offering Code.", value: "company_code", sortable: true },
      { text: "Is Criteria Matched", value: "iscriteria", sortable: true },
       
    ],

  }),

  mounted() {
   this.init();
  },

  methods: {
   
    init()
    {
       axios
      .post("/TPOCompanyScheduling/newschedulesdcopanies")
      .then((res) => {
        if (res.data.status == "200") {
          this.company_list = res.data.company_list;
          
        } else if(res.data.status == "404") {
          this.showSnackbar("#b71c1c",res.data.msg);
        }else{
            this.showSnackbar("#b71c1c","Something Went Wrong");
        }
      })
      
    },
  },
};
</script>
<style scoped>
.ec-title {
  background-image: linear-gradient(-100deg, #f4faba, #d64907);
  color: #fff;
}
.c-title {
  background-image: linear-gradient(-90deg, #befcf7, #03948d);
  color: #fff;
}
.tc-title {
  background-image: linear-gradient(-90deg, #fff, #057996);
  color: #fff;
  border-radius: 3px;
}
.btn-title {
  background-image: linear-gradient(-100deg, #8aa5f7, #063bdb);
  color: #fff;
}
.card-title {
  background-image: linear-gradient(-90deg, #8edffd, #034c70);
  color: #fff;
}
.lbl {
  color: #1705e6;
}
.v-text-field {
  padding-top: 0px;
  margin-top: 0px;
}

.v-icon-size {
  font-size: 20px;
}

.edit-avatar {
  transition: 0.3s;
  border: 1px solid #b0bec5;
}

.edit-avatar:hover {
  background-color: #b0bec5;
}

.edit-v-icon {
  padding-left: 6px;
  font-size: 15px;
}

.edit-v-icon:hover {
  color: white;
}
.downbtn {
  float: right;
}
.btn-title1 {
  background-image: linear-gradient(-100deg, #6cf389, #0b9761);
  color: #fff;
}

</style>